import React from 'react';
import { navigate } from 'gatsby';

import imageDebtFreeBanner from '../../assets/images/illustrations/debt-free-banner.png'

import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import { SecondaryDescription, SecondaryTitle } from '../../components/Typography';
import Button from '../../components/Button';
import CustomImage from '../../components/Image/Image';

const FourthSection = ({ isMobile }) => {
    return (
      <section className="fourth-section">
        <SectionWrapper>
          <CustomHeadingSection 
            leftContent={(
              <>
                {!isMobile && (
                  <CustomImage src={imageDebtFreeBanner}/>
                )}
              </>
            )}
            rightContent={(
              <div className="text-container">
                <SecondaryTitle>
                  Imagine your life {!isMobile && <br />}
                  {" "} without a mortgage
                </SecondaryTitle>
                {isMobile && (
                  <CustomImage src={imageDebtFreeBanner}/>
                )}
                <SecondaryDescription>
                At Sprive, our mission help homeowners take back control of their mortgage, have less debt and save in interest. 
                </SecondaryDescription>
                <SecondaryDescription>
                We want to empower you with the knowledge and tools to be able to save extra towards your mortgage and closer to living mortgage-free, just by living your everyday life.
                </SecondaryDescription>
                <Button 
                  buttonText="Learn more"
                  handleClick={() => navigate("/about")}
                />
              </div>
            )}
          />
        </SectionWrapper>
      </section>
    );
};

export default FourthSection